import "./style.scss";
import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { FormEvent, useState } from "react";

import { useTelephoneNumber } from "../../../hooks/useTelephoneNumber";

interface ProfileUpdateStepProps {
  stepFinished: () => void;
}

export function PhoneVerification(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const { showErrorToast } = useToast();
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const agent = useDefinedAgent();

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) {
        Keyboard.hide();
      }
      if (phoneNumber !== agent?.phone) {
        setVerificationError(true);
        return;
      }

      stepFinished();
    } catch (error) {
      showErrorToast("Unexpected error occurred while verifying ssn");
    } finally {
      setUpdatingData(false);
    }
  };

  const { formattedPhoneNumber, onChange, onKeyPress, onKeyUp, phoneNumber } = useTelephoneNumber({
    initialValue: "",
    onNext,
  });

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="Your phone doesn't match"
        message="The phone number you typed doesn't match our current records. Do you want to try typing it again? Make sure to input the same one you use for your account today."
        buttons={[
          {
            text: "Cancel",
            handler: () => setVerificationError(false),
          },
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height no-scroll">
        <div className="form-container no-scroll">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What is your phone number?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">PHONE NUMBER</IonLabel>
                  <IonInput
                    className="form-input hcp-self-input"
                    type="tel"
                    inputmode="tel"
                    placeholder="(XXX) XXX-XXXX"
                    onIonChange={onChange}
                    value={formattedPhoneNumber}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                    autofocus
                    required
                    no-border
                  />
                </div>
                <IonText className="profile-update-helper-text ion-text-start ion-margin-top">
                  We are asking you for your phone number to prevent fraudlent attempts of
                  controlling your account.
                </IonText>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={updatingData || phoneNumber.length !== 10}
            onClick={onNext}
          >
            Continue
            {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
}
