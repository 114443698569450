import "./style.scss";
import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { LocalStorage } from "@store/session";
import { FormEvent, useState } from "react";

import { useTelephoneNumber } from "../../../hooks/useTelephoneNumber";
import { checkIfPhoneExists } from "../../onboarding/components/api";

interface ProfileUpdateStepProps {
  stepFinished: () => void;
}

export function EnterPhone(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const { showErrorToast } = useToast();
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const agent = useDefinedAgent();

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) {
        Keyboard.hide();
      }
      setUpdatingData(true);
      const { exists } = await checkIfPhoneExists(phoneNumber);
      setUpdatingData(false);
      if (exists) {
        setVerificationError(true);
        return;
      }

      localStorage.setItem(LocalStorage.NEW_PHONE, phoneNumber);

      stepFinished();
    } catch (error) {
      showErrorToast("Unexpected error occurred while verifying ssn");
    } finally {
      setUpdatingData(false);
    }
  };

  const { formattedPhoneNumber, onChange, onKeyPress, onKeyUp, phoneNumber } = useTelephoneNumber({
    initialValue: String(agent.phone),
    onNext,
  });

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="This number is already in use"
        message="The phone number you tried to submit is already in use by another account. Please make sure you are submitting a phone number you own and that you typed it correctly."
        buttons={[
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What&#39;s your new phone number?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">PHONE NUMBER</IonLabel>
                  <IonInput
                    className="form-input"
                    type="tel"
                    inputmode="tel"
                    placeholder="(XXX) XXX-XXXX"
                    onIonChange={onChange}
                    value={formattedPhoneNumber}
                    onKeyUp={onKeyUp}
                    onKeyPress={onKeyPress}
                    autofocus
                    required
                  />
                </div>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={updatingData || phoneNumber.length !== 10 || phoneNumber === agent.phone}
            onClick={onNext}
          >
            Continue
            {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
}
