import "./style.scss";
import { IonButton, IonCol, IonLabel, IonRow, IonSpinner } from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { ActionType } from "@store/session";
import { validateAgentOTP } from "@store/session/api";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { requestOTP } from "./api";
import { formatNumberToPhoneNumber } from "../../../hooks/useTelephoneNumber";
import { OtpInputs } from "../../components/auth/otpInputs";

const CODE_LENGTH = 6;
interface ProfileUpdateStepProps {
  stepFinished: () => void;
  showInputBorder?: boolean;
}

/**
 * @deprecated Use VerifyOtpDialog and useStepUpAuth instead. See SignNewAgreementPage for reference
 */
export function OTPVerification(props: ProfileUpdateStepProps) {
  const { stepFinished, showInputBorder = false } = props;
  const [code, setCode] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const agent = useDefinedAgent();
  const { showSuccessToast, showErrorToast } = useToast();
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();
  const phoneRef = useRef(agent.phone);

  useEffect(() => {
    requestOTP(phoneRef.current);
  }, []);

  const verify = async (newCode?) => {
    const otpCode = newCode + "";
    if (isVerifying) {
      return;
    }
    if (otpCode.trim().length !== CODE_LENGTH) {
      return;
    }
    if (isButtonDisabled) {
      setButtonDisabled(false);
    }
    try {
      setIsVerifying(true);
      const { error } = await validateAgentOTP(String(agent?.phone), otpCode);
      setIsVerifying(false);
      if (error) {
        showErrorToast("Invalid OTP");
        return;
      }

      stepFinished();
    } catch (error) {
      showErrorToast("Unexpected error occurred while verifying OTP");
    } finally {
      dispatch({
        type: ActionType.SET_IS_VERIFYING_DONE,
      });
    }
  };

  const resendOTP = async () => {
    const { error } = await requestOTP(agent.phone);
    if (!error) {
      showSuccessToast("OTP re-sent successfully.");
    }
  };

  return (
    <>
      <div className="content-layout signup-content adjust-form-height verifyotp-content ">
        <div className="form-container">
          <IonRow>
            <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
              <div className="form-heading">
                <h4>Enter your verification code</h4>
                <p>
                  We’ve just sent a verification code to {formatNumberToPhoneNumber(agent.phone)}{" "}
                </p>
              </div>
              <div>
                <IonLabel className="form-label hcp-self-label">Verification Code</IonLabel>
                <OtpInputs
                  verify={verify}
                  code={code}
                  setCode={setCode}
                  noBorder={!showInputBorder}
                />
              </div>
              <a className="resend-otp-link" tabIndex={0} role={"button"} onClick={resendOTP}>
                Didn't get a code? Resend it now.
              </a>
            </IonCol>
          </IonRow>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            onClick={verify}
            disabled={isVerifying || isButtonDisabled}
            id="verify-button"
          >
            Continue
            {isVerifying && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
          </IonButton>
        </div>
      </div>
    </>
  );
}
