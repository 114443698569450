import "../style.scss";
import "react-image-crop/dist/ReactCrop.css";

import { isDefined } from "@clipboard-health/util-ts";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { useDefinedUserId } from "@src/app/store/helperHooks";
import { useCapturePhoto } from "@src/app/utils/mediaUpload";
import { logEvent } from "@src/appV2/lib/analytics";
import { add, arrowBack, cloudUpload, create } from "ionicons/icons";
import { useRef, useState } from "react";
import ReactCrop, { PercentCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import { useDispatch } from "react-redux";

import { uploadProfileImage } from "./api";
import { getCroppedImage } from "./utils";
import { USER_EVENTS } from "../../../../constants/userEvents";
import { updateAgent } from "../../../store/session";

const INITIAL_PERCENTAGE_CROP = 60;
interface UpdateProfileImageProps {
  profileUrl: string; // This may be an empty string
  onClose: () => void;
}

export function UpdateProfileImage(props: UpdateProfileImageProps) {
  const { profileUrl, onClose } = props;
  const [isLoading, setLoading] = useState(false);
  const userId = useDefinedUserId();
  const [selectedFile, setSelectedFile] = useState<{
    file: string | undefined;
    type: string;
  }>();
  const [reactPercentCrop, setReactPercentCrop] = useState<PercentCrop>({
    unit: "%",
    width: INITIAL_PERCENTAGE_CROP,
    height: INITIAL_PERCENTAGE_CROP,
    x: 20,
    y: 20,
  });
  const imageRef = useRef<HTMLImageElement>(null);
  const { capturePhoto } = useCapturePhoto({
    onCaptured: (capturedFiles) => {
      if (capturedFiles) {
        const { file, type } = capturedFiles;
        setSelectedFile({ file, type });
      }
    },
  });

  const dispatch = useDispatch();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const openGallery = async () => {
    capturePhoto();
  };

  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) {
      return;
    }

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFile({ file: reader.result as string, type });
    };
    event.target.value = null;
  };

  const handleTakePhoto = () => {
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery() : inputFileRef?.current?.click();
  };

  return (
    <>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerDirection="back"
              fill="clear"
              size="small"
              color="light"
              onClick={() => onClose()}
            >
              <IonIcon icon={arrowBack} mode="ios" />
            </IonButton>
          </IonButtons>
          <IonTitle>Profile Photo</IonTitle>
          {profileUrl && (
            <IonButton /** First time user is shown different screen to upload his profile image. */
              slot="end"
              fill="clear"
              size="small"
              color="light"
              onClick={handleTakePhoto}
            >
              <IonIcon icon={create} mode="ios" />
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="colleague-list-row">
          <p>Facilities use your photo to confirm your identity.</p>
          <p>Current photos with no filter are encouraged.</p>
        </div>
        {!selectedFile &&
          (profileUrl ? (
            <IonImg class="ion-margin profile-image-view" src={profileUrl} alt="Profile" />
          ) : (
            <div className="profile-image-editor-placeholder">
              <div onClick={handleTakePhoto} role={"button"} tabIndex={0}>
                <IonIcon icon={add} mode="ios" style={{ fontSize: "32px" }} />
                Upload
              </div>
            </div>
          ))}
        {selectedFile && (
          <>
            <div className="profile-image-upload-preview">
              <ReactCrop
                className="ion-margin react-image-crop"
                crop={reactPercentCrop}
                aspect={1}
                ruleOfThirds
                keepSelection
                minWidth={144}
                minHeight={144}
                onChange={(_pixelCrop, percentCrop) => setReactPercentCrop(percentCrop)}
              >
                <img
                  src={selectedFile.file}
                  alt="Profile"
                  ref={imageRef}
                  onLoad={async (event) => {
                    const { naturalWidth: width, naturalHeight: height } = event.currentTarget;
                    const percentCrop = centerCrop(
                      makeAspectCrop(
                        {
                          unit: "%",
                          width: INITIAL_PERCENTAGE_CROP,
                        },
                        1,
                        width,
                        height
                      ),
                      width,
                      height
                    );

                    setReactPercentCrop(percentCrop);
                  }}
                />
              </ReactCrop>
            </div>
            <IonButton
              class="ion-margin"
              expand="block"
              color="primary"
              disabled={isLoading}
              onClick={async () => {
                setLoading(true);
                if (!isDefined(imageRef.current)) {
                  throw new Error("ImageRef is not defined.");
                }
                logEvent(USER_EVENTS.UPDATED_PROFILE_PHOTO);
                const croppedImageFile = await getCroppedImage(imageRef.current, reactPercentCrop);
                const profileImageUrl = await uploadProfileImage({
                  croppedImageFile,
                  userId,
                });
                dispatch(updateAgent({ profileImageUrl }));
                setLoading(false);
                onClose();
              }}
            >
              {isLoading ? (
                <>
                  <IonSpinner slot="start" name="crescent" />
                  Uploading
                </>
              ) : (
                <>
                  <IonIcon slot="start" icon={cloudUpload} mode="ios" />
                  Upload
                </>
              )}
            </IonButton>
          </>
        )}
      </IonContent>
      <input
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </>
  );
}
