import "./style.scss";
import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonInput,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { useDefinedAgent } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { FormEvent, useState } from "react";

interface ProfileUpdateStepProps {
  stepFinished: () => void;
}

export function EmailVerification(props: ProfileUpdateStepProps) {
  const { stepFinished } = props;
  const { showErrorToast } = useToast();
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const agent = useDefinedAgent();
  const [email, setEmail] = useState("");

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) {
        Keyboard.hide();
      }
      if (email.toLowerCase() !== agent?.email?.toLowerCase()) {
        setVerificationError(true);
        return;
      }
      stepFinished();
    } catch (error) {
      showErrorToast("Unexpected error occurred while verifying email");
    }
  };

  const onEmailChange = (event: Event) => {
    const mail = event.target?.["value"] as string;
    setEmail(mail);
  };

  return (
    <IonContent>
      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="Your email doesn't match"
        message="The email you typed doesn't match our current records. Do you want to try typing it again? Make sure to input the same one you use for your account today."
        buttons={[
          {
            text: "Cancel",
            handler: () => setVerificationError(false),
          },
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What is your email?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">Email Address</IonLabel>
                  <IonInput
                    name="email"
                    className="form-input no-border"
                    type="email"
                    placeholder="Email address"
                    onIonChange={onEmailChange}
                    value={email}
                    autofocus
                    required
                    no-border
                  />
                </div>
                <IonText className="profile-update-helper-text ion-text-start ion-margin-top">
                  We are asking you for your email to prevent fraudlent attempts of controlling your
                  account.
                </IonText>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={!email}
            onClick={onNext}
          >
            Continue
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
}
